// Then import Bootstrap
$theme-colors: (
				"primary": #D32D91,
);
@import "../node_modules/bootstrap/scss/bootstrap";

$dark: rgba(7, 7, 7, 1);
$light:#fff;

.whatsapp-icon {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 100;
	animation: whatsapp-bounce 1s infinite alternate; /* Adiciona a animação de balanço */
}

@keyframes whatsapp-bounce {
	from {
		transform: translateY(0); /* Inicia a animação sem deslocamento */
	}
	to {
		transform: translateY(10px); /* Desloca o ícone para baixo */
	}
}

.whatsapp-icon i {
	font-size: 2.2rem; /* Tamanho do ícone */
	color: #fff; /* Cor do ícone */
	background-color: #25d366; /* Cor de fundo do ícone, se desejar */
	border-radius: 50%; /* Para tornar o ícone circular, se preferir */
	padding: 10px; /* Espaçamento interno do ícone */
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Adicione sombra ao ícone */
	cursor: pointer; /* Altera o cursor para indicar que é clicável */
}


html,
body {
	background: $dark;
	font-size: 20px;
	color: white;
	overflow-x: hidden;
	max-width: 100%;
}

.mobile {
	display: none;
}
 a {
	 text-decoration: none;
 }

.under-line {
	position: relative;
	display: inline-block;

	&:before {
		content: '';
		background: url("./images/Underlined.png");
		background-size: 100% 15px;
		background-repeat: no-repeat;
		width: 105%;
		left: -0%;
		height: 20px;
		display: block;
		position: absolute;
		bottom: -15px;
		line-height: 0;
		z-index: -1;
	}
}

.max-width {
	max-width: 1920px;
	margin: auto;
	padding: 0px 44px;
}

.header {
	padding-top: 3vw;
	display: flex;
	align-items: center;

	.navbar-brand {
		padding-right: 6.25vw;
	}

	.navbar-nav {
		display: flex;
		margin: 0px;
		flex-direction: row;

		.nav-item {
			margin: 0px;
			padding: 0px 1.6vw;
		}
	}
}

.about-section {
	display: flex;
	width: 100%;
	font-size: 1.1vw;

	.info {
		flex: 0 0 60%;
		padding-top: 8.3vw;
		padding-bottom: 19.2vw;
	}

	h1 {
		font-size: 4.7vw;
	}

	.stores-badges {
		padding-top: 60px;
	}

	.stores-badges {
		img {
			height: 2.8vw;
		}
		margin-right: 10px;
	}

	.image-mobile {
		display: none;
	}

	.image {
		flex: 1;
		position: relative;

		img {
			width: 100%;
			position: absolute;
			top: -5%;
		}
	}
}

.arena-section {
	display: flex;
	font-size: 1.1vw;
	padding-top: 14.1vw;
	position: relative;

	.info {
		flex: 0 0 45%;
		margin-left: auto;

		b {
			color: #ce008f;
		}
	}

	.under-line {
		position: relative;
		display: inline-block;

		&:before {
			content: '';
			background: url("./images/Underlined.png");
			background-size: 94% auto;
			background-repeat: no-repeat;
			width: 100%;
			height: 10px;
			display: block;
			position: absolute;
			bottom: -0px;
			line-height: 0;
			z-index: -1;
		}
	}

	h1 {
		font-size: 2.6vw;
		padding-bottom: 70px;
	}

	.image {
		flex: 1;
		position: relative;

		img {
			width: 120%;
			position: absolute;
			left: -20%;
			top: -12.1vw;
		}
	}
}

.resources-info {
	padding-top: 8vw;
	font-size: 1.1vw;
	padding-bottom: 13vw;

	.resource-image {
		width: 75vw;
		transform: translateX(-1.4vw);
	}

	h1 {
		font-size: 2.5vw;
	}

	.resource-container {
		display: flex;
		width: 100%;

		.info {
			padding-top: 4vw;
			flex: 0 0 50%;

			.text {
				width: 22vw;
			}

			b {
				color: #f2c300;
			}
		}

		.image {
			flex: 1;
			position: relative;

			img {
				width: 160%;
				position: absolute;
				left: -58%;
				top: -17vw;
			}
		}
	}
}

.footer {
	font-size: 1.1vw;
	display: flex;
	width: 100%;
	padding-bottom: 5vw;

	.info-section {
		flex: 0 0 60%;
	}

	.form-container {
		flex: 1;

		.form {
			background: #111111;
			padding: 2vw;
			border-radius: 10px;
			width: 32.12vw;
		}
	}

	.stores-badges {
		a {
			padding-right: 1vw;
		}
	}
	.app-copyright {
		padding-top: 5vw;
	}

	h1 {
		font-size: 3.2vw;
	}

	h3 {
		font-size: 1.8vw;
	}

	p {
		padding-top: 10px;
		color: rgba(234, 234, 234, 0.4);
		max-width: 470px;
		text-align: justify;
		line-height: 30px;
	}

	.copyrights p {
		color: #fff;
		font-size: 14px;
		line-height: 14px;
		max-width: 280px;
	}

	label {
		font-size: 1.0vw;
	}

	.small {
		font-size: 10px !important;
	}

	.stores-badges {
		img {
			height: 2.8vw;
		}
		margin-right: 10px;
	}
}

.radios {
	$color1: #EAEAEA;
	$color2: #D32D91;

	span {
		display: inline-block;
	}

	.radio {
		margin: 0.5rem;
		input[type="radio"] {
			position: absolute;
			opacity: 0;
			+ .radio-label {
				&:before {
					content: '';
					background: $color1;
					border-radius: 100%;
					border: 1px solid darken($color1, 25%);
					display: inline-block;
					width: 2em;
					height: 2em;
					position: relative;
					top: -0.2em;
					margin-right: 1em;
					vertical-align: top;
					cursor: pointer;
					text-align: center;
					transition: all 250ms ease;
				}
			}
			&:checked {
				+ .radio-label {
					&:before {
						background-color: $color2;
						box-shadow: inset 0 0 0 8px $color1;
					}
				}
			}
			&:focus {
				+ .radio-label {
					&:before {
						outline: none;
						border-color: $color2;
					}
				}
			}
			&:disabled {
				+ .radio-label {
					&:before {
						box-shadow: inset 0 0 0 4px $color1;
						border-color: darken($color1, 25%);
						background: darken($color1, 25%);
					}
				}
			}
			+ .radio-label {
				&:empty {
					&:before {
						margin-right: 0;
					}
				}
			}
		}
	}
}

@media (max-width: 1440px) {
	.header {
		padding-top: 3vw;
		display: flex;
		align-items: center;

		.navbar-brand {
			padding-right: 6.25vw;
		}

		.navbar-nav {
			display: flex;
			margin: 0px;
			flex-direction: row;

			.nav-item {
				margin: 0px;
				padding: 0px 1.6vw;
			}
		}
	}

	.about-section {
		font-size: 1.5vw;

		.stores-badges {
			img {
				height: 3.5vw;
				margin-bottom: 10px;
			}
		}
	}

	.arena-section {
		font-size: 1.5vw;
	}

	.resources-info {
		font-size: 1.5vw;
	}

	.footer {
		font-size: 1.5vw;

		label {
			font-size: 1.5vw;
		}

		.stores-badges {
			img {
				height: 3.5vw;
				margin-bottom: 10px;
			}
		}
	}
}

@media (max-width: 1020px) {
	.desktop {
		display: none;
	}

	.mobile {
		display: block;
	}

	.max-width {
		padding: 0px 30px;
	}

	.header {
		.navbar-nav {
			display: none !important;
		}
	}

	.navbar-brand {
		img {
			width: 70vw;
			padding-top: 2vw;
		}
	}

	.about-section {
		.image {
			display: none;
		}

		font-size: 3.8vw;

		.info {
			flex: 0 0 97%;
		}

		h1 {
			font-size: 7.0vw;
		}

		p {
			color: #7D7D7D;
		}

		.stores-badges {
			padding-top: 5vw;

			a {
				margin-right: 10px;
			}
			img {
				height: 9.0vw;
			}
		}

		.image-mobile {
			display: block;

			flex: 1;
			position: relative;

			img {
				height: 130.0vw;
				position: absolute;
				right: -82.0vw;
				top: -10.0vw;
			}
		}
	}


	.arena-section {
		font-size: 3.8vw;
		padding-top: 14.1vw;
		flex-direction: column;

		.info {
			padding-top: 90vw;
			b {
				color: #ce008f;
			}
		}

		h1 {
			font-size: 7.0vw;
			padding: 0px;
		}

		.image {
			flex: 1;
			position: initial;

			img {
				width: 150vw;
				position: absolute;
				top: initial;
				left: -25vw;
				transform: translateY(-10vw);
			}
		}
	}


	.resources-info {
		padding-top: 0vw;
		font-size: 3.8vw;

		.resource-image {
			width: 85vw;
			transform: translateX(-1.4vw);
		}

		h1 {
			font-size: 2.5vw;
		}

		.second-line {
			width: 70%;
		}

		.resource-container {
			display: flex;
			width: 100%;

			.info {
				padding-top: 4vw;
				flex: 0 0 100%;

				.text {
					width: 100%;
				}
			}

			.image {
				img {
					width: 180vw;
					left: -130vw;
					top: 30vw;
					position: absolute;
				}
			}
		}
	}

	.footer {
		padding-top: 3.2vw;
		font-size: 3.2vw;
		flex-direction: column;

		.form-container {
			flex: 1;

			.form {
				background: #111111;
				padding: 6vw;
				border-radius: 10px;
				width: 100%;
			}
		}

		.stores-badges {
			a {
				padding-right: 1vw;
			}
		}

		h1 {
			font-size: 9.2vw;
		}

		h3 {
			font-size: 4.8vw;
		}

		p {
			font-size: 3.4vw;
			line-height: 140%;
		}

		.app-copyright {
			padding-top: 15vw;
			text-align: center;

			p {
				text-align: center;
			}
		}

		.copyrights p {
			color: #fff;
			font-size: 14px;
			line-height: 14px;
			max-width: 100%;
		}

		label {
			font-size: 3.5vw;
		}

		.small {
			font-size: 10px !important;
		}


		.stores-badges {
			img {
				height: 11.0vw;
			}
			margin-right: 10px;
		}

		input {
			height: 40px;
		}

		.mb-4 {
			margin-bottom: 4.5vw !important;
		}
	}
}